
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  NavBar,
  Icon,
  Button,
  Image,
  Swipe,
  SwipeItem,
  Tabs,
  Tab,
  Popup,
  Stepper,
  Dialog,
  Checkbox,
} from "vant";

@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox,
    [Stepper.name]: Stepper,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class Detail extends Mixins(Mixin) {
  @Prop({ default: 0, type: Number }) public couponId!: number;
  public couponDetail: good.CouponDto = {};
  public id = 0;
  public mark = false;
  created(): void {
    this.id = this.$route.query.couponId
      ? Number(this.$route.query.couponId)
      : 0;
    window.document.title = "优惠券详情";
    this.getCouponDetails();
  }

  // 获取优惠券详情
  getCouponDetails(): void {
    let id = this.id ? this.id : this.couponId;
    this.$api.goodApi.coupon.getCouponDetail(id, (data: { data: any }) => {
      this.mark = true;
      this.couponDetail = data.data;
    });
  }
  onClickLeft(): void {
    if (this.id) {
      this.$router.go(-1);
    } else {
      this.$emit("close");
    }
  }
  onClickRight(): void {
    let name = "优惠券规则";
    let type = "COUPON";
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }
}
